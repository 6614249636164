import { useState } from 'react'
import { ImageListItem, Modal } from '@mui/material'

const Image = ({ src, alt }: { src: string; alt: string }) => {
  const [imageRadius, setImageRadius] = useState('5px')
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <ImageListItem>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <img
          src={src}
          alt={alt}
          style={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            maxWidth: '80vw',
            maxHeight: '80vh',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#EFEFEF',
            boxShadow: '24',
            padding: 20,
            borderRadius: 3,
          }}
        />
      </Modal>
      <img
        style={{
          borderRadius: imageRadius,
          transition: 'border-radius 0.5s',
          cursor: 'pointer',
        }}
        src={src}
        srcSet={src}
        alt={alt}
        loading='lazy'
        onMouseEnter={() => {
          setImageRadius('40px')
        }}
        onMouseLeave={() => {
          setImageRadius('5px')
        }}
        onClick={handleOpen}
      />
    </ImageListItem>
  )
}
export default Image
