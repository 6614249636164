import './App.css'
import { Route, Switch } from 'react-router-dom'
import { Typography, Grid, Container } from '@mui/material'
// import Menu from './components/Menu'
import About from './components/About'
import Contact from './components/Contact'

function App() {
  return (
    <Container>
      {/* <Menu /> */}
      <Grid
        container
        justifyContent={'center'}
        xs={12}
        sx={{ marginTop: '0em', marginBottom: '2.5em' }}
        style={{ minHeight: '70vh' }}
      >
        <Switch>
          <Route exact path='/' component={About} />
          <Route exact path='/about' component={About} />
          <Route exact path='/contact' component={Contact} />
        </Switch>
      </Grid>
      <Typography
        variant='body1'
        component='p'
        sx={{
          textAlign: 'center',
          fontSize: '0.5em',
          marginBottom: '1em',
          marginTop: '5em',
        }}
      >
        &#169; Thunman Timmerhantverk, made by{' '}
        <a href='https://erkers.dev' target={'blank'}>
          erkers.dev
        </a>
      </Typography>
    </Container>
  )
}

export default App
