import { Container, ImageList, useMediaQuery } from '@mui/material'
import Image from './Image'

const images = [
  {
    src: './images/tt1_100.webp',
    alt: 'THUNMAN TIMMERHANTVERK',
  },
  {
    src: './images/tt2_100.webp',
    alt: 'THUNMAN TIMMERHANTVERK',
  },
  {
    src: './images/tt3_100.webp',
    alt: 'THUNMAN TIMMERHANTVERK',
  },
  {
    src: './images/tt4_100.webp',
    alt: 'THUNMAN TIMMERHANTVERK',
  },
  {
    src: './images/tt5_100.webp',
    alt: 'THUNMAN TIMMERHANTVERK',
  },
]

const About = () => {
  const matches = useMediaQuery('(min-width: 900px)')
  return (
    <Container>
      <img
        alt='logo'
        src='./images/logo_black_512.png'
        style={{
          width: '50%',
          maxWidth: 256,
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '2em',
          marginBottom: '2em',
        }}
      />

      <h1>Thunman Timmerhantverk...</h1>
      <p>
        ...består utav mig, Tobias Thunman, som är utbildad timmerman via
        Sjöviks folkhögskola. Med utgångspunkt i Gävle arbetar jag med allt från
        att renovera och bevara gamla timmerhus till att bygga nya med samma
        tradionella hantverk som förr i tiden med stort fokus på den lokala
        kulturmiljön.
      </p>

      <p>
        Oavsett om ni behöver hjälp med att renovera ett äldre timmerhus så som
        att byta ut ruttna eller fuktskadade stockar eller om ni drömmer om att
        bygga ett nytt gästhus eller vindskydd så är ni välkomna att höra av er.
      </p>

      <h3 style={{ margin: 0 }}>
        <a href='mailto:timmer.thunman@gmail.com' target={'blank'}>
          Maila mig
        </a>
      </h3>
      <h3 style={{ margin: 0 }}>
        <a href='https://instagram.com/thunman_timmerhantverk' target={'blank'}>
          Instagram
        </a>
      </h3>

      <a href='tel:+46735004958'>
        <img
          alt='phone number'
          src='./images/phone_black.png'
          style={{
            width: '50%',
            maxWidth: 400,
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '2em',
            marginBottom: '4em',
          }}
        />
      </a>

      <ImageList cols={matches ? 2 : 1} variant={'masonry'} gap={10}>
        {images.map((image) => {
          return <Image src={image.src} alt={image.alt} />
        })}
      </ImageList>
    </Container>
  )
}

export default About
