import { Container } from '@mui/material'

const Contact = () => {
  return (
    <Container>
      <h1>KONTAKT</h1>
        <p>
          <a
            href='mailto:tobias@thunmantimmerhantverk.se'
            target={'blank'}
            style={{ fontSize: '1em' }}
          >
            Maila mig
          </a>
        </p>
        <p>
          <a
            href='https://instagram.com/thunman_timmerhantverk'
            target={'blank'}
            style={{ fontSize: '1em' }}
          >
            Instagram
          </a>
        </p>
      <p>Tel: +4673 500 49 58</p>
    </Container>
  )
}
export default Contact
